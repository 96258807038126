import React, { useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import SEO from "../components/globals/seo";
import Layout from "../components/containers/layout";
import Section from "../components/containers/section";
import Container from "../components/containers/container";
import Link from "../components/links/link";
import Box from "../components/containers/box";
import BlogBanner from "../components/banners/blogBanner";
import BlogList from "../components/blog/blogList";
import BlogListItem from "../components/blog/blogListItem";
import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import Image from "../components/globals/image";
import {
  FacebookLogo,
  TwitterLogo,
  LinkedInLogo,
} from "../components/svg/logos";
import Video from "../components/video/video";
import { buildDate } from "../utils/helperFunctions";

const ContentWrapper = styled(Box)`
  position: relative;

  div[data-oembed-type="video"] {
    padding-bottom: 56.25%; /* 16:9 */
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  h2,
  h3,
  h4,
  h5 {
    color: ${p => p.theme.blueDark};
    font-size: 30px;
    line-height: 32px;
  }

  div,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 20px;
  }

  p {
    color: ${p => p.theme.greyDark};
    font-size: ${p => p.theme.baseFontSizeTabMob};
  }

  a {
    font-family: ${p => p.theme.headingFont};
  }
  a:hover {
    color: ${p => p.theme.green};
  }

  li {
    color: ${p => p.theme.greyDark};
    list-style-type: disc;
    margin-left: 30px;
    margin-bottom: 12px;
  }

  @media (min-width: ${p => p.theme.screenL}) {
    div,
    h2,
    h3,
    h4,
    h5,
    p {
      margin-bottom: 30px;
    }
  }
  @media (min-width: ${p => p.theme.screenXL}) {
    h2,
    h3,
    h4,
    h5,
    p {
      margin-bottom: 40px;
    }
    p {
      font-size: ${p => p.theme.baseFontSizeDesktop};
    }
  }
`;

const FacebookShareButton = styled.button`
  background-color: transparent;
  border: none;

  :hover {
    cursor: pointer;
  }
`;

const LogoLinks = styled(Box)`
  max-width: 992px;
  padding-top: 30px;

  > * {
    display: inline-block;
    margin-right: 30px;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    vertical-align: middle;
  }

  @media (min-width: ${p => p.theme.screenM}) {
    margin: auto;
    padding-top: 60px;
  }

  @media (min-width: ${p => p.theme.screenL}) {
  }
`;

const DateWrap = styled(Box)`
  font-size: ${p => p.theme.smallFontSize};
`;

const ImgCrop = styled(Box)`
  overflow: hidden;
  width: 100%;
  height: 210px;
  border-radius: 3px;

  img {
    width: 100%;
    border-radius: 3px;
    height: auto;
  }

  @media (min-width: ${p => p.theme.screenL}) {
    max-width: 370px;
  }
`;

const BlogPostPage = ({ data, theme }) => {
  // Attach twitter widgets once on page load.
  useEffect(() => {
    const script = document.createElement(`script`);
    script.src = `https://platform.twitter.com/widgets.js`;
    document.getElementsByClassName(`twitter-embed`)[0].appendChild(script);
  }, []);

  // Grabbing data from Prismic
  const {
    uid,
    data: { date: postDate, yt_video_id, title, content, image, hashtags },
  } = data.activePost.edges[0].node;

  // Twitter hashtag string logic
  const hashtagsParam = hashtags !== null ? `&hashtags=${hashtags}` : ``;
  // Twitter crashes on share click without encoding (% was causing issues in Prismic title)
  const urlEncodeTitle = (title) => {
    const titleArr = title.split(``);
    const titleArrEscaped = titleArr.map((character) => {
      if (character === ` `) return `%20`;
      if (character === `%`) return `%25`;

      return character;
    });

    return titleArrEscaped.join(``);
  };
  const ogTitle = title.text.replace(`-`, ` `);
  return (
    <Layout navbarPadOnly>
      <SEO
        ogUrl={`https://toptekkers.club/blog/${uid}`}
        ogImage={image.url}
        title={ogTitle}
      />

      <BlogBanner>
        <Container>
          <DateWrap color={theme.white} mb={[`20px`, null, `40px`]}>
            {postDate ? buildDate(postDate) : null}
          </DateWrap>
          <Heading color={theme.white} maxWidth="none" mb="140px">
            {title.text}
          </Heading>
        </Container>
      </BlogBanner>

      {/* Highlight */}
      <Section pt="30px" pb={[40, null, 70]}>
        <Container>
          {/* image */}
          <Box
            mt={[-160, null, -150]}
            maxHeight="675px"
            overflow="hidden"
            borderRadius="3px"
            mb={[`40px`, null, `65px`]}
          >
            <Image
              fluid={image.localFile.childImageSharp.fluid}
              alt={image.alt}
              width="100%"
              borderRadius="3px"
            />
          </Box>

          <Box mx={[`0`, `auto`]} maxWidth="992px">
            <ContentWrapper
              dangerouslySetInnerHTML={{ __html: content.html }}
            />
            {/* needs better index */}
            {/* <ContentWrapper>
            {content.raw.map((item, index) => {
              return htmlSerializer(item, index)
            })}
          </ContentWrapper> */}

            {yt_video_id && (
              <Video>
                <iframe
                  title="TopTekkers blog related video"
                  src={`https://www.youtube.com/embed/${yt_video_id}`}
                  width="100%"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </Video>
            )}

            <LogoLinks>
              <Text mb="0">Share</Text>
              {/* Twitter Share */}
              <div className="twitter-embed">
                <a
                  href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Ftoptekkers.club%2Fblog%2F${uid}&text=${urlEncodeTitle(
                    title.text,
                  )}&via=TopTekkersApp${hashtagsParam}`}
                >
                  <TwitterLogo size="s" fill={theme.blueDark} />
                </a>
              </div>

              {/* Both Facebook and LinkedIn:
            Behavior: 
            When setting url param to active page the share button throws a 404 (have tested with build/serve)
            When setting url param to any live page it it is using info from index page
            Using URL params of any type is showing no difference from the index page information.
            
            Expected:
            Share buttons use og tags (set in each page via SEO component) to populate a share (LinkedIn customisation very limited without business acc) */}

              {/* Facebook Share */}
              <FacebookShareButton
                onClick={() => {
                  FB.ui(
                    {
                      method: `share`,
                      href: `https://toptekkers.club/blog/${uid}`,
                    },
                    function(response) {},
                  );
                }}
              >
                <FacebookLogo size="s" fill={theme.blueDark} />
              </FacebookShareButton>

              {/* LinkedIn Share */}
              {/* <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Ftoptekkers.club%2Fblog%2F${uid}&title=${title.text}`}
              target="_blank"
              rel="noopener noreferer"
            >
              <LinkedInLogo size="s" fill={theme.blueDark} />
            </a> */}
            </LogoLinks>
          </Box>
        </Container>
      </Section>

      <Section pt={[`40px`, null, `70px`]}>
        <Container>
          <BlogList>
            {data.recentPosts.edges.map((post) => {
              const { fluid } = post.node.data.image.localFile.childImageSharp;

              return (
                <BlogListItem key={post.node.id}>
                  <Link
                    to={`/blog/${post.node.uid}`}
                    color={theme.green}
                    fontSize={theme.baseFontSizeTabMob}
                  >
                    <ImgCrop>
                      <Image fluid={fluid} alt={post.node.data.image.alt} />
                    </ImgCrop>

                    <DateWrap pt="30px" mb="0" color={theme.greyDark}>
                      {post.node.data.date
                        ? buildDate(post.node.data.date)
                        : null}
                    </DateWrap>

                    <Heading as="h2" fontSize="30px" lineHeight="38px">
                      {post.node.data.title.text}
                    </Heading>

                    <span>Read more</span>
                  </Link>
                </BlogListItem>
              );
            })}
          </BlogList>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query($currentUID: String!) {
    activePost: allPrismicBlog(filter: { uid: { eq: $currentUID } }) {
      edges {
        node {
          uid
          data {
            date
            yt_video_id
            hashtags
            content_type
            title {
              text
            }
            content {
              html
            }
            image {
              alt
              localFile {
                ...imageFragmentMax
              }
            }
          }
        }
      }
    }
    recentPosts: allPrismicBlog(
      sort: { fields: data___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          uid
          data {
            date
            title {
              text
            }
            content {
              raw {
                text
              }
            }
            image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 370) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

BlogPostPage.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(BlogPostPage);
